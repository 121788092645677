import React from 'react'

import { FullPage, Slide } from 'react-full-page'
import ReactPlayer from 'react-player'

import { Image, Button, Container, Row, Col } from 'react-bootstrap'
import thirdImage from './assets/third-section.jpeg'
import sectionVideo from './assets/4.mp4'
import team1 from './assets/team-1.jpg'
import team2 from './assets/team-2.jpg'
import team3 from './assets/team-3.jpg'

import Header from './components/Header'
import Footer from './components/Footer'
import MintComponent from './components/MintComponent'
import { ToastProvider  } from 'react-toast-notifications';

// Custom Style
import './App.css'

const App = () => {
  return (
    <FullPage controls={false} scrollMode={'normal'} >
      <Header />

      {/* Section 1 */}
      <Slide className='top__section'>
        <div className='container'>
          <div className='row text-center'>
            <div className='col-md-9 m-auto'>
              <h1>DRAFT YOUR PANDA</h1>
              <p className='sub__heading'>
                The future of digital basketball is here. Ball your way to the
                top and win a championship.
              </p>
              <Button variant='primary' href='#mint'>Mint a Panda</Button>
            </div>
          </div>
        </div>
      </Slide>

      {/* Section 2 */}
      <Slide className='second__section' id='pandas'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-6 content'>
              <h2>The Panda</h2>
              <p className='content'>
                B-ball Pandas are 10,000 professional basketball playing Pandas
                competing on the blockchain for supremacy. Panda owners get to
                compete in interactive basketball games for cash prizes and legacy.
                Owning a Panda's allows you to receive physical merch like
                custom jerseys, gold championship rings, 3D moments and join the
                B-ball Panda Locker Room.
              </p>
              <Button variant='primary' href='#mint'>Mint a Panda</Button>
            </div>
            <div className='col-md-6 video'>
              <ReactPlayer
                url={sectionVideo}
                controls={false}
                playing={true}
                muted={true}
                loop={true}
                width='auto'
                style={{ textAlign: 'center' }}
              />
            </div>
          </div>
        </div>
      </Slide>

      {/* Section 3 */}
      <Slide className='third__section' id='distribution'>
        <Container>
          <Row>
            <Col md={5}>
              <Image src={thirdImage} fluid />
            </Col>
            <Col md={7}>
              <h2>Distribution</h2>
              <ul>
                <li> 🛍️ Now 0.04 Eth</li>
                <li>2.5% royalty</li>
                <li>No bonding curve</li>
                <li>No delayed reveal</li>
                <li>ERC-721 Tokens</li>
                <li>OpenZeppelin based smart contract for added security</li>
                <li>IPFS stored</li>
                <li>Player stats integrate with OpenSea</li>
                <li>Full commercial rights to B-ball Panda owners</li>
              </ul>
              <p>
                On this court bonding curves are a Flagrant 2, only fair distribution will be tolerated.
                B-ball Pandas cost 0.04 ETH for everyone. We also don’t believe in delayed reveals.
                Your Panda will appear in your wallet as soon as they are minted.
              </p>
              {/* <Button variant='primary' href='#mint'>Mint a Panda</Button> */}
            </Col>
          </Row>
        </Container>
      </Slide>

      {/* Mint Section */}
      <Slide className='third__section' id='mint'>
        <ToastProvider placement='bottom-center'>
          <MintComponent />
        </ToastProvider>
      </Slide>

      {/* Section 4 */}
      <Slide className='forth__section' id='team'>
        <Container>
          <Row className='text-center'>
            <Col md={12} className='mb-5'>
              <h2>Team</h2>
            </Col>

            <Col md={3}>
              <Image src={team2} fluid className='mb-5' />
              <h3>John</h3>
            </Col>
            <Col md={3}>
              <Image src={team1} fluid className='mb-5' />
              <h3>MadMax</h3>
            </Col>
            <Col md={3}>
              <Image src={team3} fluid className='mb-5' />
              <h3>Hugh</h3>
            </Col>
          </Row>
        </Container>
      </Slide>

      {/* Section 5 */}
      <Slide className='fifth__section' id='roadmap'>
        <Container>
          <Row>
            <Col md={12}>
              <h2>The Roadmap</h2>
              <div className='mt-5'>
                <p className='text'>PRESALE</p>
                <p className='text'>
                  Owning a B-ball Panda is more than just an NFT or a game, it is also a membership with various airdrops and perks!
                </p>
                <ul>Randomly selected B-ball Pandas holders will receive Custom Framed Prints.
                  <li>20% - Randomly selected B-ball Pandas holders will receive Custom Prints.</li>
                  <li>40% - 250 3D B-ball Panda Moments airdropped to randomly selected holders</li>  
                  <li>60% - Members Only Merch Store Opens( Gold B-ball Panda Championship Rings, Jerseys, Collectible Toys, Panda Sneakers and much more)</li>
                  <li>80% - Breeding Activated!</li>
                  <li>100% - B-ball Panda game comes out the locker room</li>
                </ul>
              </div>
            </Col>
          </Row>
        </Container>
      </Slide>

      <Footer />
    </FullPage>
  )
}

export default App
