import { ethers } from "ethers";
const addresses = require("./addresses");

async function getTotalMinted(){
  if (typeof window.ethereum !== "undefined") {
    await window.ethereum.enable();
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const PandasContract = new ethers.Contract(
      addresses.PandasContract,
      [
        "function totalSupply() public view returns (uint256)",
      ],
      provider
    );
    const supply = await PandasContract.totalSupply();
    return supply.toNumber() + 1;
  }
}

async function mintPandas(numberOfTokens) {  
  if (typeof window.ethereum !== "undefined") {
      await window.ethereum.request({ method: 'eth_requestAccounts' });
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      const PandasContract = new ethers.Contract(
        addresses.PandasContractProxy,
        [
          "function mint(uint256) public payable",
        ],
        signer
      );
      try {
          const ProxyBalance = await provider.getBalance(addresses.PandasContractProxy);
          const MintableTokensInProxy = ProxyBalance.div(ethers.BigNumber.from("40000000000000000")).toNumber();
          console.log(MintableTokensInProxy);
          if(numberOfTokens <= MintableTokensInProxy){
            const overrides = {
              value: ethers.utils.parseEther("0.04").mul(numberOfTokens)
            }          
            const result = await PandasContract.mint(numberOfTokens, overrides); 
            return {success: true, result: result};         
          }else{
            return {success: false, error: {code : 'INSUFFICIENT_BALANCE_PROXY'}};
          }
      } catch (error) {
          if('error' in error && 'message' in error.error && error.error.message === 'execution reverted: Purchase would exceed max supply of Pandas'){
            return {success: false, error: {code : 'EXCEDING_MAX_SUPPLY'}};
          }else if('error' in error && 'message' in error.error && error.error.message === 'execution reverted: Sale must be active to mint Panda'){
            return {success: false, error: {code : 'SALE_NOT_ACTIVE'}};
          }else{
            return {success: false, error: error};
          }
      }
  }else{
    return {success: false, error: {code: 'METAMASK_NOT_INSTALLED'}};
  }    
}

export { mintPandas, getTotalMinted };
