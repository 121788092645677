import React from 'react'
import { Navbar, Nav, Button, Container } from 'react-bootstrap'

const Header = () => {
  return (
    <header>
      <Navbar fixed='top' expand='lg'>
        <Container>
          <Navbar.Brand href='#home'>B-ball Pandas</Navbar.Brand>
          <Navbar.Toggle aria-controls='basic-navbar-nav' />
          <Navbar.Collapse id='basic-navbar-nav'>
            <Nav className='m-auto'>
              <Nav.Link href='#pandas'>The Pandas</Nav.Link>
              <Nav.Link href='#distribution'>Distribution</Nav.Link>
              <Nav.Link href='#team'>Team</Nav.Link>
              <Nav.Link href='#roadmap'>Roadmap</Nav.Link>
            </Nav>
            <Button variant='primary' href='#mint'>Mint a Panda</Button>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  )
}

export default Header
