import React, {useState, useEffect} from 'react'
import { Button, Card, Image } from 'react-bootstrap'
import RangeSlider from 'react-bootstrap-range-slider';
import { useToasts } from 'react-toast-notifications';
import {isMobile} from 'react-device-detect'

import { mintPandas, getTotalMinted } from '../contracts/pandas.js';
import mintImage from '../assets/mint-section.png'

const MintComponent = () => {

  const [ numberOfTokens, setNumberOfTokens ] = useState(1);
  const [ totalMinted, setTotalMinted ] = useState();
  const { addToast } = useToasts();
  const cardWidth = isMobile ? '20rem' : '30rem';

  useEffect(() => {
    let interval = setInterval(async () => {
      setTotalMinted(await getTotalMinted())
    }, 1000);
    return () => clearInterval(interval);
  }, [totalMinted]);

  return (
    <div className='container'>
      <div className='row text-center'>
        <Card style={{ width: cardWidth }}>
          <Card.Img variant="top" as={Image} src={mintImage} fluid={true} />
          <Card.Body>
            <Card.Title>🐼 <b>Mint a panda!</b> 🐼</Card.Title>
            <Card.Text>
              0.04 ETH for each panda + gas fees ⛽. Recommended to mint many at once
              (maximum 20) to economize gas fees.
            </Card.Text>
            <Button variant='primary' onClick={async () => {
              const result = await mintPandas(numberOfTokens);
              console.log(result);
              if(result !== undefined && !result.success){
                switch (result.error.code) {
                  case 'METAMASK_NOT_INSTALLED':
                    addToast('Please install Metamask wallet 🦊', { appearance: 'error' });
                    break;
                  case 'INSUFFICIENT_FUNDS':
                    addToast('Please check if wallet has sufficient funds 💴', { appearance: 'warning' });
                    break;
                  case 'EXCEDING_MAX_SUPPLY':
                    addToast('This purchase is exceding the max supply of Pandas. Please try again with a smaller quantity 🐼', { appearance: 'warning' });
                    break;
                  case 'SALE_NOT_ACTIVE':
                    addToast('Sale not yet active. Please try again later. 🛒', { appearance: 'warning'});
                    break;
                  case 'INSUFFICIENT_BALANCE_PROXY':
                    addToast('Interface contract without sufficient funds. Please contact us at Discord. 🗣', { appearance: 'warning'});
                    break;
                  default:
                    addToast(result.error.message, { appearance: 'error' });
                    break;
                }
              }
            }}>Mint {numberOfTokens} {(numberOfTokens > 1)? "Pandas" : "Panda"}! 🐼</Button>
            <p></p>
            <b>Quantity  </b>
            <RangeSlider
              min={1}
              max={20}
              value={numberOfTokens}
              tooltip='off'
              size='sm'
              onChange={e => setNumberOfTokens(e.target.value)}
            />
            <p></p>
            <b>Total minted: </b> {totalMinted}/10000
          </Card.Body>
        </Card>
      </div>
    </div>
  )
}

export default MintComponent
